import { __decorate } from "tslib";
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import BackupsList from '@/components/shared/BackupsList.vue';
let BackupsListModal = class BackupsListModal extends Vue {
    onBackupSelected(backup) {
        this.$refs.modal.close();
        return backup;
    }
};
__decorate([
    Prop()
], BackupsListModal.prototype, "organizationId", void 0);
__decorate([
    Emit()
], BackupsListModal.prototype, "onBackupSelected", null);
BackupsListModal = __decorate([
    Component({
        components: {
            BackupsList,
        },
    })
], BackupsListModal);
export default BackupsListModal;
